import { StateProvider } from 'apprise-frontend-core/state/provider'
import { PropsWithChildren, useContext } from 'react'
import { ToggleContext, ToggleState, initialToggleState } from './context'



export type ToggleProps<T extends string = string> = PropsWithChildren<Partial<ToggleState<T>>>

export const Toggles = <T extends string = string>(props: ToggleProps<T>) => {

    const { active = [], filter = () => true, children } = props

    const state: ToggleState = {

        ...initialToggleState,

        // context is untyped, so we erase specific types at this point.
        filter: filter as any,

        active: active,
    }

    return <StateProvider initialState={state} context={ToggleContext}>
        {children}
    </StateProvider>

}


export const useToggles = () => {

    const state = useContext(ToggleContext)

    const { active, filter } = state.get()

    return active.filter(filter)

}


