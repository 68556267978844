import { State } from 'apprise-frontend-core/state/api'
import { createContext } from 'react'




export const initialToggleState: ToggleState = {

    // context is untyped, so we erase specific types at this point.
    active: [],
    filter: () => true
}



export type ToggleState<T extends string = string> = {

    active: readonly T[]
    filter:(_: T) => boolean
}


// state-holding context, serves default until config mgmt is active, if ever.
export const ToggleContext = createContext<State<ToggleState>>(undefined!)


